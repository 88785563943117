.cuerpo{
  border-image-repeat: repeat;
  background-size: cover;
}
.texto-preguntas{
  background-color: white;
  font-size: 1em;
  text-align: right;
  height: auto;
  margin: 1% 5%;
  padding: 1%;
  border-radius: 6px;
}
.hora-preguntas{
  font-size: 0.7em;
  text-align: right;
  margin: 0% 5%;
  padding: 0% 1% 1% 1%;
}

.ant-form-item{
  margin-bottom: 1%!important;
}
.btn-enviar{
  color: #ffffff!important;
  background-color: #000000!important;
  border-radius: 6px!important;
  font-size: 0.7em!important;
  padding: 1px 10px!important;
  border-color: #000000!important;
}
.ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split{
  color:#ffffff;
}
.sujeto{
  color: #ffffff;
  font-size: 0.6em;
  text-align: left;
}

.header-preguntas{
  background-color: #f47925;
  color: #ffffff;
  font-size: 1.5em;
  padding-top: 2%;

}
.ver-preguntas{
  background-color: #ffffff;
  height: 350px;
  overflow: scroll;
}
.area_encuesta{
  background-color: #eb4b1c;
  color: #ffffff;
  font-size: 2em;
  text-align: left;
  padding: 5px 0px 0px 5px;
}
.area_resultados{
  background-color: #000000;
  color: #ffffff;
  font-size: 2em;
  text-align: left;
  padding: 5px 0px 0px 5px;
}