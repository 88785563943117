.contenedor_panel{
    background-color: #f1f1f1;
    padding: 4% 3%;
    margin: 2%;
}
.nombre_conf_panel{
    color:#8e50f6;
    text-align: center;
    font-weight: bold;
    font-size: 28px;
    line-height: 1;
}
.desc_conf_panel{
    color:#000000;
    text-align: justify;
    font-size: 18px;
    line-height: 1;}
.img_parsona_panel{
    width: 200px;
}
.contenedor_persona_panel{
    padding: 3%;
}
.nombre_persona_panel{
    color:#8e50f6;
    text-align: left;
    font-weight: bold;
    font-size: 24px;
    line-height: 1;
    margin: 0px;
    padding: 0px;
}
.empresa_persona_panel{
    color:#8e50f6;
    text-align: left;
    font-size: 18px;
    line-height: 1;
}
.img_bandera_panel{
    width: 20px;
}
.descipcion_persona_panel{
    color:#000000;
    text-align: justify;
    font-size: 14px;
    line-height: 1;
}