  
  .label-demo{
    color: black;
  }
  .ant-form-item-required{
    color: black !important;
  }
  .boton-demo{
    background-color: #01356f !important;
    border-color: #01356f !important;
    color: black !important;
  }
  .ant-tabs-tab-btn{
    color:white !important;
    font-size: 1.3em;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color:white !important;
    font-size: 1.3em;
  }
  .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
    color: white!important;
  }
  .ant-tabs-ink-bar {
    background-color: white!important;
  }
  .botonP{
    width: 120px;
    background-color: #ffffff;
    color: black;
    border: 1px solid black;
    text-align: center;
    margin-bottom: 5%;
  }
  .botonP:hover{
    background-color: #01356f;
    border: 5px solid #01356f;
  
  }