.img_caracteristicas{
 width: 100%;
}
.texto_caracteristicas{
  text-align: center;
  font-size: 18px;
  color: #666666;
}
.titulo_acercade{
  text-align: center;
  font-size: 32px;
  color: #ad14ee;
  font-family: 'Aileron-hard';
}
.titulo_patrocinadores{
  text-align: center;
  font-size: 32px;
  color: #162e86;
  font-family: 'Aileron-hard';
}
.texto_acercade{
  text-align: center;
  font-size: 18px;
  line-height: 1;
  color: #162e86;
}
.mini_tutluo_acercade{
  color: #162e86;
  font-size: 12px;
  text-align: left;
  line-height: 1;
  font-weight: bold;
}
.img_acercade_icono{
  width: 100%;
}
@media only screen and (max-width:762px) {
  .titulo_acercade{
    text-align: center;
    font-size: 26px;
  }
  .texto_acercade{
    text-align: center;
    font-size: 14px;
  }
  .mini_tutluo_acercade{
    font-size: 10px;
  }
}