  .btn_confetencia_div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 50px;
    margin: 0px 0 0 -150px;
    text-align: center;
    border-radius: 8px;
    border: 3px solid rgb(116, 65, 203);
    cursor: pointer;
    animation: border-flicker 2s linear forwards;
    cursor: pointer;
  }
  
  .btn_confetencia_div:hover {
    
  }
  
  .link_btn_confe {
    font-family: "Open Sans";
    font-weight: 300;
    font-size:2em;
    margin: 15px 0 0;
    color: rgba(21, 118, 173, .5);
    animation: letter-flicker 3s linear infinite;
  }
  
  .btn_confetencia_div:hover .link_btn_confe {
    animation: text-flicker 2s linear forwards;
    
  }
  
  .btn_confetencia_div:hover .first {
  }
  
  @keyframes text-flicker {
    2% { 
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
    3% {
      color: rgba(21, 118, 173, .5);
      text-shadow: none;
    }
    6% {
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
    9% {
      color: rgba(21, 118, 173, .5);
      text-shadow: none;
    }
    11% {
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
    14% {
      color: rgba(21, 118, 173, .5);
      text-shadow: none;
    }
    18% {
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
    32% {
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
    33% {
      color: rgba(21, 118, 173, .5);
      text-shadow: none;
    }
    37% {
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
    39% {
      color: rgba(21, 118, 173, .5);
      text-shadow: none;
    }
    43% {
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
    46% {
      color: rgba(21, 118, 173, .5);
      text-shadow: none;
    }
    47% {
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
    100% {
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
  }
  
  @keyframes letter-flicker {
      2% { 
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
    3% {
      color: rgba(21, 118, 173, .5);
      text-shadow: none;
    }
    6% {
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
    9% {
      color: rgba(21, 118, 173, .5);
      text-shadow: none;
    }
    11% {
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
    14% {
      color: rgba(21, 118, 173, .5);
      text-shadow: none;
    }
    18% {
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
    32% {
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
    33% {
      color: rgba(21, 118, 173, .5);
      text-shadow: none;
    }
    37% {
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
    39% {
      color: rgba(21, 118, 173, .5);
      text-shadow: none;
    }
    40% {
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
    100% {
      color: rgb(33 170 247);
      text-shadow: 0 0 15px rgb(33 170 247);
    }
  }
  
  @keyframes border-flicker {
    2% {
      border: 3px solid rgb(142 80 246);
      box-shadow: 0 0 15px -1px rgb(142 80 246), 
      0 0 12px -1px rgb(142 80 246) inset;
    }
    3% {
      border: 3px solid rgb(116, 65, 203);
      box-shadow: none;
    }
    5% {
      border: 3px solid rgb(142 80 246);
      box-shadow: 0 0 15px -1px rgb(142 80 246), 
      0 0 12px -1px rgb(142 80 246) inset;
    }
    6% {
      border: 3px solid rgb(116, 65, 203);
      box-shadow: none;
    }
    7% {
      border: 3px solid rgb(142 80 246);
      box-shadow: 0 0 15px -1px rgb(142 80 246), 
      0 0 12px -1px rgb(142 80 246) inset;
    }
    9% {
      border: 3px solid rgb(116, 65, 203);
      box-shadow: none;
    }
    13% {
      border: 3px solid rgb(142 80 246);
      box-shadow: 0 0 15px -1px rgb(142 80 246), 
      0 0 12px -1px rgb(142 80 246) inset;
    }
    16% {
      border: 3px solid rgb(116, 65, 203);
      box-shadow: none;
    }
    18% {
      border: 3px solid rgb(142 80 246);
      box-shadow: 0 0 15px -1px rgb(142 80 246), 
      0 0 12px -1px rgb(142 80 246) inset;
    }
    22% {
      border: 3px solid rgb(116, 65, 203);
      box-shadow: none;
    }
    34% {
      border: 3px solid rgb(142 80 246);
      box-shadow: 0 0 15px -1px rgb(142 80 246), 
      0 0 12px -1px rgb(142 80 246) inset;
    }
    36% {
      border: 3px solid rgb(116, 65, 203);
      box-shadow: none;
    }
    54% {
      border: 3px solid rgb(142 80 246);
      box-shadow: 0 0 15px -1px rgb(142 80 246), 
      0 0 12px -1px rgb(142 80 246) inset;
    }
    100% {
      border: 3px solid rgb(142 80 246);
      box-shadow: 0 0 15px -1px rgb(142 80 246), 
      0 0 12px -1px rgb(142 80 246) inset;
    }
  }