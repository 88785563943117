/* EVENTO */
.btn_registrate_home{
  background-color: #ffffff;
  color:#8e50f6;
  font-size: 28px;
  padding: 5px 45px;
  border-radius: 20px;
  margin-bottom: 0px;
}

.btn_registrate_login{
  background-color: #8e50f6;
  color:#ffffff;
  font-size: 20px;
  padding: 5px 25px;
  border-radius: 20px;
  margin-bottom: 0px;
  font-weight: bold;
  letter-spacing: 2px;
}

.contenedor_cintillo_abajo{
  background-image: linear-gradient(to right, rgba(173,20,228,1), rgba(36,182,233,1));
  padding: 2% 1%;
}
.text_cintillo{
  text-align: left;
  font-size: 20px;
  color: #ffffff;
  line-height: 1.2;
  font-family: 'Aileron-Bold';
  margin:0px;
}
.logos_ies{
  width: 70%;
}

.center{
  vertical-align: middle;
}
.contenedor_description{
  background-color: #162e86;
  padding: 3%;
}
.texto_descripcion{
  text-align: center;
  font-size: 24px;
  color: #ffffff;
  font-weight: bold;
  line-height: 1;
}
.subtexto_descripcion{
  text-align: center;
  font-size: 18px;
  line-height: 1;
  color: #ffffff;
}
/* For demo */


.principal{
  background-image: url('../images/sitio/Banners/Sinergia_BannerPrincipal.jpg');
  border-image-repeat: no-repeat;
  height: 660px;
  background-size: cover;
  padding-top: 100px;
}
.principal_movil{
  background-image: url('../images/sitio/Banners/Sinergia_BannerPrincipal.jpg');
  border-image-repeat: no-repeat;
  height: 400px;
  background-size: cover;
  padding-top: 100px;
}
.paquetes_back{
  background-image: url('../images/temporal/1920x800.png');
  border-image-repeat: no-repeat;
  background-size: cover;
  padding: 4% 0%;
}
.titulo{
  color: #ffffff;
  font-size: 22px;
  line-height: 1;
  margin-top: 20px;
  text-align: center;
  -webkit-text-stroke: 0.3px black;
}
.fecha_all{
  color: #eb4b1c;
  font-size: 45px;
  line-height: 1;
  font-weight: bold;
}
.fecha_banner{
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  margin: 0px;
}
.numero{
  color: #ffffff;
  font-size: 28px;
  font-weight: bold;
  font-family: 'Nulshock';
  padding-bottom: 10px;
}
.fecha{
  color: #ffffff;
  font-size: 32px;
  line-height: 1;
  font-weight: bold;
  font-family: 'Nulshock';
}
.contador{
  line-height: 1;
}
.contadorf{
  line-height: 1;
}
.subtitle{
  color: #ffffff;
  font-size: 24px;
  line-height: 1;
  margin-top: 3%;
}
.ponentes1{
  border-image-repeat: no-repeat;
  height: 550px;
  background-size: cover;
}
.ponentes2{
  border-image-repeat: no-repeat;
  height: 550px;
  background-size: cover;
}
.ponente{
  width: 100%;
}

.col_flechas_ponentes_izquierda{
  position: absolute;
  z-index: 99;
  top: 83%;
  left: 70%;
}
.col_flechas_ponentes_derecha{
  position: absolute;
  z-index: 99;
  top: 83%;
  left: 80%;
}
.img_carousel_flecha{
  width: 50px;
  z-index: 99;
}
.img_carousel_flecha.flecha_izquierda:hover{
  width: 50px;
  content: url('');
}
.img_carousel_flecha.flecha_derecha:hover{
  width: 50px;
  content: url('');
}
.programa{
  background-image: url('');
  border-image-repeat: no-repeat;
  background-size: cover;
}
.sin_margin{
  margin: 0px;
}
.info-horario{
  align-items:center;
}
.dia_agenda_home{
  color:#21aaf7;
  font-weight: bold;
  font-size: 24px;
  margin: 0px;
  cursor: pointer;
  border-color: #21aaf7;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
}
.border_right{
  border-right: 1px solid #21aaf7;
}
.border_left{
  border-left: 1px solid #21aaf7;
}
.contenedor_row_agenda{
  border-top: 4px solid #21aaf7;
}
.flecha_agenda{
  color: #000!important;
  font-size: 32px;
}

.titulo_agenda_evento{
  color: #666666;
  font-size: 34px;
  margin: 0px;
}
.dia_agenda_home:hover{
  color:#21aaf7;
}
.dia_agenda_home_select{
  color:#ffffff;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 0px;
  cursor: pointer;
  background-color: #f47925;
  border-color: #21aaf7;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
}
.bordes_agenda{
  border-color: #21aaf7;
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  padding:0px;
  margin:0px;
}
.paddin_dentro{
  padding: 15px 5px;
}
.back_gris_agenda{
  background-color: #e9e6e6;
}
.dia_agenda_home_select:hover{
  color:#ffffff;
}

.tituPrograma{
  color:#ffffff;
  margin-bottom: 0px;
  border-bottom: 4px solid #ffffff;
  margin: 3% 0%;
  font-size: 3em;
  font-weight: bold;
}
.visualizar{
  border-bottom: 1px solid #000000;
}
.info-hora{
  color:#666666;
  font-size: 24px;
}
.info-fecha{
  color:#000000;
  font-size: 1.3em;
}
.info-nombre{
  color:#666666;
  font-size: 22px;
  font-weight: lighter;
  padding-bottom: 9px;
}
.info-descripcion{
  color:#21aaf7;
  font-size: 22px;
}
.info-hora_2{
  color:#ffffff;
  font-size: 24px;
}
.info-nombre_2{
  color:#ffffff;
  font-size: 22px;
  font-weight: lighter;
  padding-bottom: 9px;
}
.info-descripcion_2{
  color:#ffffff;
  font-size: 28px;
  font-weight: lighter;
}
.info-video{
  color:#000000;
}

.entradas{
  background-image: url('');
  border-image-repeat: no-repeat;
  background-size: cover;
  
}
.divEntradas{
  text-align: right;
  line-height: 1;
}
.tituEntradas{
  color:#eb4b1c;
  margin: 3% 0% 0% 0%;
  font-size: 3em;
}
.textEntradas{
  color:#000000;
  font-size: 1.5em;
}
.tituLogos{
  font-size: 24px;
  text-align: center;
  color: #666666;
}
/*Para carusel*/
.div_patrocinador{
  display: table-cell!important;
  vertical-align: middle!important;
  height: 200px;
}
.img_patrocindaor{
  padding: 10px 0px;
}
@media (min-width:1802px) {
  .principal{
    background-image: url('../images/sitio/Banners/Sinergia_BannerPrincipal.jpg');
    border-image-repeat: no-repeat;
    height: 860px;
    background-size: cover;
    padding-top: 100px;
  }
}
@media only screen and(max-width:1102px) {
  .numero{
    color: #ffffff;
    font-size: 50px;
  }
}
@media only screen and (max-width:992px) {
  .logos_ies{
    width: 90%;
  }
  .dia_agenda_home_select{
    border-color: #21aaf7;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
  }
  .dia_agenda_home{
    border-color: #21aaf7;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
  }
  .titulo{
    color: #ffffff;
    font-size: 18px;
    margin-top: 10px;
  }
  .fecha_all{
    font-size: 25px;
  }
  .contador{
    line-height: 1;
  }
  .fecha{
    font-size: 12px;
  }
  .numero{
    color: #ffffff;
    font-size: 30px;
  }
  .principal{
    border-image-repeat: no-repeat;
    height: 500px;
    background-size: cover;
  }
  
  .subtitle{
    color: #ffffff;
    font-size: 16px;
    line-height: 1;
    margin-top: 3%;
  }
  .info-hora{
    color:#000000;
    font-size: 16px;
  }
  .info-fecha{
    color:#000000;
    font-size: 1em;
  }
  .info-nombre{
    font-size: 18px;
  }
  .info-descripcion{
    font-size: 20px;
  }
  .tituPrograma{
    font-size: 2em;
  }
  .dia_agenda_home{
    font-size: 16px;
  }
  .dia_agenda_home_select{
    font-size: 16px;
  }
  .tituEntradas{
    font-size: 2em;
  }
  .textEntradas{
    color:#000000;
    font-size: 0.9em;
  }
  .tituLogos{
    font-size: 16px;
  }
  .col_flechas_ponentes_izquierda{
    position: absolute;
    z-index: 99;
    top: 90%;
    left: 30%;
  }
  .col_flechas_ponentes_derecha{
    position: absolute;
    z-index: 99;
    top: 90%;
    left: 60%;
  }
  .img_carousel_flecha{
    width: 40px;
  }
  .img_carousel_flecha.flecha_izquierda:hover{
    width: 40px;
    content: url('');
  }
  .img_carousel_flecha.flecha_derecha:hover{
    width: 40px;
    content: url('');
  }
  
}
@media only screen and (max-width:767px) {
  .principal_movil{
    height: 350px;
    background-size: cover;
    padding-top: 100px;
  }
  .div_patrocinador{
    height: 100px;
  }
  .texto_descripcion{
    text-align: center;
    font-size: 17px;
    color: #ffffff;
    font-weight: bold;
    line-height: 1;
  }
  .subtexto_descripcion{
    text-align: center;
    font-size: 14px;
    line-height: 1;
    color: #ffffff;
  }
  .text_cintillo{
    text-align: left;
    font-size: 16px;
    color: #ffffff;
    margin-bottom:10px;
  }
}
@media only screen and (max-width:662px) {
  .principal_movil{
    height: 300px;
    background-size: cover;
    padding-top: 100px;
  }
}
@media only screen and (max-width:462px) {
  .principal_movil{
    height: 250px;
    background-size: cover;
    padding-top: 100px;
  }
}
.dia_agenda_home_perfil{
  color:black;
  font-weight: bold;
  font-size: 20px;
  padding:10px;
}
.dia_agenda_home_select_perfil{
  color:#01c7e2;
  font-weight: bold;
  font-size: 20px;
  padding:10px;
}
.dia_agenda_home_select_perfil:hover{
  color:#01c7e2;
}

.tituPrograma_perfil{
  color:#ffffff;
  margin-bottom: 0px;
  border-bottom: 0px solid #ffffff;
  font-size: 3em;
  font-weight: bold;
  padding-bottom: 0px;
}