.footer{
  background-color: black;
}
.logo_footer{
  width: 80%;
}
.text-blanco{
  color: #ffffff;
  text-align: left;
}
.text-blanco:hover{
  color: #ffffff;
  text-align: left;
}
.redes{
  color: #ffffff;
  font-size: 30px;
  margin: 0px 5px
}
.link-footer{
  color: #ffffff;
  text-decoration: none;
  text-align: left;
}
.link-footer:hover{
  color: #eb4b1c;
  text-decoration: none;
  text-align: left;
}
.titu-footer{
  color: #eb4b1c;
  font-size: 2.5em;
  text-align: left;
  margin-bottom: 10px;;
}
.contenedor_footer{
  padding: 2%;
  background-color: #162e86;
}
.btn_footer_mail{
  width: 100%;
}
.texto_footer{
  color: #ffffff;
  text-align: center;
  font-size: 32px;
  margin:0px;
}

@media (max-width:997px) {
  .logo_footer{
    width: 80%;
  }
  .redes{
    font-size: 20px;
  }
}