body{
    font-family: 'Aileron-Regular'!important;
}  
a:hover{
    color: unset!important;
}
.color_blanco{
    color: #ffffff;
}
.color_naranja{
    color: #f47925;
}
.color_amarillo{
    color: #e9e90a;
}
.back_amarillo{
    color: #e9e90a;
}
.color_azul_oscuro{
    color: #162e86;
}
.back_azul_oscuro{
    color: #162e86;
}
.color_morado{
    color:#ad14ee;
}
.back_morado{
    color:#ad14ee;
}
.back_naranja{
    background-color: #f47925;
}
.color_azul{
color: #24b6e9;
}
.back_azul{
    background-color: #24b6e9;
}
.color_gris{
    color: #666666;
}
.back_gris{
    background-color: #666666;
} 
.back_negro{
    background-color: #000000;
} 

@font-face{
    font-family: 'Aileron-Bold';
    src: url('../fonts/Aileron/Aileron-Bold.otf');
}
@font-face{
    font-family: 'Aileron-hard';
    src: url('../fonts/Aileron/Aileron-Heavy.otf');
}
@font-face{
    font-family: 'Aileron-BItalic';
    src: url('../fonts/Aileron/Aileron-BoldItalic.otf');
}
@font-face{
    font-family: 'Aileron-Italic';
    src: url('../fonts/Aileron/Aileron-Italic.otf');
}
@font-face{
    font-family: 'Aileron-Regular';
    src: url('../fonts/Aileron/Aileron-Regular.otf');
}
@font-face{
    font-family: 'Nulshock';
    src: url('../fonts/Nulshock/nulshockbd.otf');
}
.aileron_bold{
    font-family: 'Aileron-Bold';
}
.nulshock{
    font-family: 'Nulshock';
}
.ponter_select{
    cursor: pointer;
  }
  .ponter_select_no{
    cursor:initial;
  }