.titulo_seccion{
    font-size: 32px;
    text-align: center;
    font-family: 'Aileron-hard';
    font-weight: bolder;
}
.hora_agenda{
    font-size: 22px;
    color:#ffffff;
    text-align: right;
    line-height: 1;
    font-family: 'Aileron-hard';
    font-weight: bolder;
}

.actividad_agenda{
    font-size: 22px;
    color:#ffffff;
    text-align: left;
    line-height: 1;
    font-family: 'Aileron-hard';
    font-weight: bolder;
    
}
.descripcion_agenda{
    font-size: 18px;
    color:#ffffff;
    text-align: left;
    line-height: 1.1;
    font-family: 'Aileron-Regular';
    
}
.contenedor_agenda_back{
    padding: 4% 6%;
    background-color: #f2f2f2;
    background-attachment: fixed;
    background-image: url('../images/sitio/Banners/Sinergia_FondoAgenda.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.cambios_agenda{
    text-align: center;
    font-size: 18px;
    color:black;
}
.conferencia_modal_actividad{
    color: #8e50f6;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}
.nombre_modal_actividad{
    color: #8e50f6;
    font-size: 18px;
    text-align: center;
}
.descripcion_modal_actividad{
    color: #000000;
    font-size: 14px;
    text-align: justify;
}
.masinfo_hover_actividad{
    font-size: 12px;
    color: #8e50f6;
    cursor: pointer;
    margin: 0px;
  }
@media only screen and (max-width:762px) {
    .cambios_agenda{
        font-size: 12px;
        padding: 0px 10px;
    }
    .cotenedor_actividad{
        border: 0.5px solid #8e50f6;
        padding: 10% 2%;
        height: 100%;
        height: 36vh;
        align-items: center;
        display: flex!important;
        justify-content: center;
        
    }
    .hora_agenda{
        font-size: 18px;
        color:#ffffff;
        text-align: left;
    }
    .actividad_agenda{
        font-size: 18px;
        color:#ffffff;
        text-align: left;
        
    }
  }